import { getLocationCities, getLocationUnits } from 'api/config.service';
import { fetchBasicServices } from 'api/girl.service';
import { useRouter } from 'next/router';
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { lookFieldsConfig } from '@c/ToolMenu/FiltersModalRemake/TabLook';
import { getLocationUnitsServer } from '../api/config.service';
import axios from 'axios';

export type ParamsObject = {
  name: string;
  items: string[];
  translit?: boolean;
};
export const cyrillicToTranslit = new CyrillicToTranslit();

export type SlugParam = [ParamsObject, any];

const defineParam = (param: string, paramArray: ParamsObject[]) => {
  let foundVal = 'not_found';
  const paramObject = paramArray.find((paramsObject) => {
    const foundLatin = paramsObject.items.find(
      (item) => item.toLowerCase() === param.toLowerCase(),
    );
    const foundCyrillic = paramsObject.items.find(
      (item) =>
        cyrillicToTranslit.transform(item, '_').toLowerCase() ===
        param.toLowerCase(),
    );
    if (foundCyrillic) {
      foundVal = foundCyrillic;
    } else {
      foundVal = foundLatin;
    }
    return Boolean(foundLatin) || Boolean(foundCyrillic);
  });
  return [paramObject, foundVal];
};

const baseHierarchy = [
  'type',
  'format',
  'locationCity',
  'locationUnit',
  'age',
  'weight',
  'height',
  'breast',
  'price1_in_day',
  'services',
  ...lookFieldsConfig.map((fieldConfig) => fieldConfig.field),
];

const typeDict = {
  all: 'Проститутки и Индивидуалки',
  normal: 'Проститутки',
  elite: 'Элитные',
  bdsm: 'БДСМ',
  massage: 'Массажистки',
};

export const slugToSlugParams = async (
  slug: string[],
  params: ParamsObject[],
) => {
  if (!slug) {
    return [];
  }
  const translated = slug.map((val) => defineParam(val, params));
  const cityParam = translated.find(
    (slugParam) => slugParam?.[0]?.name === 'locationCity',
  );
  if (cityParam) {
    const res = await axios.get(
      encodeURI(
        `${process.env.NEXT_PUBLIC_BACKEND_URL}/admin/city/${cityParam[1]}`,
      ),
    );
    const locationUnitsForCity = res?.data;
    const retranslated = slug.map((val) =>
      defineParam(val, [
        ...params,
        {
          name: 'locationUnit',
          items: locationUnitsForCity.map((unit) => unit.name),
        },
      ]),
    );
    return [
      retranslated.sort((val1, val2) => {
        const index1 = baseHierarchy.findIndex(
          (type) => type === val1?.[0]?.name,
        );
        const index2 = baseHierarchy.findIndex(
          (type) => type === val2?.[0]?.name,
        );
        return index1 - index2;
      }),
      locationUnitsForCity,
    ];
  } else {
    return [
      translated.sort((val1, val2) => {
        const index1 = baseHierarchy.findIndex(
          (type) => type === val1?.[0]?.name,
        );
        const index2 = baseHierarchy.findIndex(
          (type) => type === val2?.[0]?.name,
        );
        return index1 - index2;
      }),
      [],
    ];
  }
};

export const slugToFilterObject = (
  slug: string[],
  paramsArray: ParamsObject[],
) => {
  const filterObject = {};

  if (!slug) {
    return [];
  }

  slug.forEach((val) => {
    const foundParam = defineParam(val, paramsArray);
    if (foundParam?.[0]?.name && foundParam)
      filterObject[foundParam?.[0]?.name] = foundParam?.[1];
  });
  return filterObject;
};

const formatDictType = {
  indi: 'Проститутки и Индивидуалки',
  saloon: 'Салон',
  manager: 'Менеджер',
};

const buildParamsArray = (fetchedData) => {
  const params = [
    {
      name: 'type',
      items: ['normal', 'bdsm', 'massage', 'elite', 'all'],
    },
    {
      name: 'locationCity',
      items: fetchedData?.cities,
    },
    {
      name: 'services',
      items: fetchedData?.services.map((service) => service.name),
    },
    {
      name: 'format',
      items: ['indi', 'saloon', 'manager'],
    },
  ];
  lookFieldsConfig.forEach((field) => {
    const paramsObject = {
      name: field.field,
      items: field.values.map((val) => val.id),
    };
    params.push(paramsObject);
  });
  return params;
};

export const filterParamsArray = (query: any) => {
  const bannedKeys = ['utm_source', 'utm_medium', 'utm_campaign'];
  try {
    const filtered = {};
    Object?.keys(query)?.forEach((key) => {
      if (!bannedKeys.includes(key)) {
        filtered[key] = query?.[key];
      }
    });
    return filtered;
  } catch {
    return query;
  }
};

export const getFilterState = async (currentSlug: any, inputQuery: any) => {
  const services = await fetchBasicServices();
  const cities = await getLocationCities();
  const paramsArray = buildParamsArray({ services, cities });
  const currentQuery = filterParamsArray(inputQuery);
  const [slugParams, units] = await slugToSlugParams(currentSlug, paramsArray);
  //translate current slug params to filter object
  const slugFilter = {};
  slugParams.forEach((slugParam: [ParamsObject, any]) => {
    if (slugParam[0]?.name) {
      //@ts-ignore
      slugFilter[slugParam[0].name] = slugParam[1];
    }
  });

  const cyrillicKeys = ['locationCity', 'locationUnit', 'services'];

  const preparedNewFilterCurrent = {};

  Object.keys(currentQuery).forEach((key) => {
    const shouldBeReversed = Boolean(cyrillicKeys.find((k) => key === k));
    if (shouldBeReversed) {
      preparedNewFilterCurrent[key] = arrayrize(currentQuery[key]).map((s) =>
        cyrillicaze(s),
      );
    } else {
      preparedNewFilterCurrent[key] = currentQuery[key];
    }
  });

  const completeFilter = { ...slugFilter, ...preparedNewFilterCurrent };
  return {
    completeFilter,
    slugFilter,
    queryFilter: preparedNewFilterCurrent,
    loading: false,
    params: {
      services,
      cities,
      units,
    },
  };
};

export const getCalculatedValues = (filterState) => {
  const bannedKeys = [
    'locationCity',
    'locationUnit',
    'order',
    'field',
    'utm',
    'utm_source',
    'utm_medium',
    'utm_campaign',
  ];
  //Building text promt

  const currentCity = filterState.completeFilter['locationCity'];
  const currentLocationUnit = arrayrize(
    filterState.completeFilter['locationUnit'],
  );

  const additionalTextChunks = [];
  const additionalTextKeywords = [];

  if (currentLocationUnit && currentCity) {
    const units = filterState.params.units;
    const foundUnits = units?.filter((unit: any) => {
      return currentLocationUnit.find(
        (name) =>
          cyrillicaze(latinize(name)) === cyrillicaze(latinize(unit.name)),
      );
    });
    if (foundUnits?.length > 0 && currentCity) {
      let chunkTitle = '';
      if (currentCity === 'Москва' || currentCity === 'Санкт-Петербург') {
        chunkTitle = 'Метро - ';
      } else {
        if (currentCity === 'Московская область') {
          chunkTitle = 'Города - ';
        } else {
          chunkTitle = 'Районы - ';
        }
      }
      additionalTextKeywords.push(currentCity);
      foundUnits.forEach((unit) => additionalTextKeywords.push(unit.name));
      additionalTextChunks.push(
        `${chunkTitle}${foundUnits.map((unit) => unit.name).join(', ')}`,
      );
    }
  }

  if (currentCity) {
    if (currentCity === 'Московская область') {
      additionalTextKeywords.push(currentCity);
      additionalTextChunks.push(`в Московской Области`);
    } else {
      additionalTextKeywords.push(currentCity);
      additionalTextChunks.push(`в городе ${currentCity}`);
    }
  }

  const readableFilterArray = [
    ...Object.keys(filterState.completeFilter)
      .map((key) => {
        if (!bannedKeys.includes(key))
          return filterValueToString(
            filterState.completeFilter[key],
            key,
            filterState.params.services,
          );
      })
      .filter((obj) => obj),
    ...additionalTextChunks,
  ];

  const keywordsArray = [
    ...Object.keys(filterState.completeFilter)
      .map((key) => {
        if (!bannedKeys.includes(key))
          return filterValueToKeyword(
            filterState.completeFilter[key],
            key,
            filterState.params.services,
          );
      })
      .filter((obj) => obj),
    ...additionalTextKeywords,
  ];

  const readableFilter = readableFilterArray.join(',');
  //Building route array
  const keysIncluded = ['type', 'locationCity', 'locationUnit'];
  const prepared = keysIncluded.map((key) => {
    const val = filterState.completeFilter[key];
    if (Array.isArray(val)) {
      return {
        key,
        val: undefined,
        original_val: undefined,
        label: '',
      };
    } else {
      return {
        key,
        val: cyrillicToTranslit.transform(val, '_'),
        original_val: val,
        label: filterValueToString(val, key, filterState.params.services),
      };
    }
  });

  const routerArrayLinks = prepared.map((route, index) => {
    const link =
      '/girls/' +
      prepared
        .slice(0, index + 1)
        .map((route1) => route1.val)
        .join('/');
    return { ...route, link };
  });
  const routerArrayFinal = routerArrayLinks.filter(
    (route) => route.original_val,
  );
  return {
    readableFilter,
    routerArray: routerArrayFinal,
    blogKeywords: keywordsArray,
  };
};

export const getCalculatedValuesTitle = (filterState) => {
  const bannedKeys = ['locationCity', 'locationUnit', 'order', 'field'];
  //Building text promt
  const currentCity = filterState.completeFilter['locationCity'];
  const currentLocationUnit = arrayrize(
    filterState.completeFilter['locationUnit'],
  );

  const additionalTextChunks = [];
  if (currentCity) {
    additionalTextChunks.push(`${currentCity}`);
  }
  if (currentLocationUnit && currentCity) {
    const units = filterState.params.units;
    const foundUnits = units?.filter((unit: any) => {
      return currentLocationUnit.find(
        (name) =>
          cyrillicaze(latinize(name)) === cyrillicaze(latinize(unit.name)),
      );
    });
    if (foundUnits?.length > 0 && currentCity) {
      let chunkTitle = '';
      if (currentCity === 'Москва' || currentCity === 'Санкт-Петербург') {
        chunkTitle = 'Метро - ';
      } else {
        if (currentCity === 'Московская область') {
          chunkTitle = 'Города - ';
        } else {
          chunkTitle = 'Районы - ';
        }
      }
      additionalTextChunks.push(
        `${chunkTitle}${foundUnits.map((unit) => unit.name).join(', ')}`,
      );
    }
  }

  const readableFilter = [
    ...additionalTextChunks,
    ...Object.keys(filterState.completeFilter)
      .map((key) => {
        if (!bannedKeys.includes(key)) {
          const value = filterValueToString(
            filterState.completeFilter[key],
            key,
            filterState.params.services,
          );
          return value.includes(' - ') ? value?.split(' - ')[1] : value;
        }
      })
      .filter((obj) => obj),
  ].join(' | ');
  return readableFilter;
};

const filterValueToKeyword = (val: any, type: string, services = []) => {
  switch (type) {
    case 'breast':
    case 'height':
    case 'weight':
    case 'age':
    case 'price1_in_day':
      return '';
    case 'type':
      return typeDict[val];
    case 'services':
      const servicesVal = Array.isArray(val) ? val : [val];
      return servicesVal
        .map(
          (serviceBadName) =>
            services.find(
              (serviceConfig) =>
                cyrillicaze(latinize(serviceConfig.name)) == serviceBadName ||
                serviceConfig.name === serviceBadName,
            )?.name,
        )
        .join(', ');
    case 'format':
      return formatDictType[val];
    case 'breastType':
    case 'eyeColor':
    case 'lookType':
    case 'hair':
    case 'eyeColor':
    case 'bodyType':
    case 'hairCut':
      const values = Array.isArray(val) ? val : [val];
      return values
        .map((arrVal) => {
          return lookFieldsConfig
            .find((lookConfig) => lookConfig.field === type)
            ?.values.find((value) => value.id === arrVal)?.name;
        })
        .join(', ');
    default:
      return val;
  }
};

const filterValueToString = (val: any, type: string, services = []) => {
  switch (type) {
    case 'breast':
      return `Грудь - ${val?.[0] && 'от ' + val[0]}${
        val?.[1] && ' до ' + val[1]
      }`;
    case 'height':
      return `Рост - ${val?.[0] && 'от ' + val[0] + 'см'}${
        val?.[1] && ' до ' + val[1] + 'см'
      }`;
    case 'weight':
      return `Вес - ${val?.[0] && 'от ' + val[0] + 'кг'}${
        val?.[1] && ' до ' + val[1] + 'кг'
      }`;
    case 'age':
      return `Возраст - ${val?.[0] && 'от ' + val[0]}${
        val?.[1] && ' до ' + val[1]
      }`;
    case 'price1_in_day':
      return `Час - ${val?.[0] && 'от ' + val[0] + '₽'}${
        val?.[1] && ' до ' + val[1] + '₽'
      }`;
    case 'type':
      return typeDict[val];
    case 'services':
      const servicesVal = Array.isArray(val) ? val : [val];
      return servicesVal
        .map(
          (serviceBadName) =>
            services.find(
              (serviceConfig) =>
                cyrillicaze(latinize(serviceConfig.name)) == serviceBadName ||
                serviceConfig.name === serviceBadName,
            )?.name,
        )
        .join(', ');
    case 'format':
      return formatDictType[val];
    case 'breastType':
    case 'eyeColor':
    case 'lookType':
    case 'hair':
    case 'eyeColor':
    case 'bodyType':
    case 'hairCut':
      const values = Array.isArray(val) ? val : [val];
      const config = lookFieldsConfig.find(
        (lookConfig) => lookConfig.field === type,
      );
      return (
        config.title +
        ' - ' +
        values
          .map((arrVal) => {
            return lookFieldsConfig
              .find((lookConfig) => lookConfig.field === type)
              ?.values.find((value) => value.id === arrVal)?.name;
          })
          .join(', ')
      );
    default:
      return val;
  }
};

export const useFilterSlug = (
  serverSlug: string[],
  serverQuery: string[],
  filterState = {
    completeFilter: {},
    slugFilter: {},
    queryFilter: {},
    loading: false,
    params: {
      services: [],
      cities: [],
      units: [],
    },
  },
  calculatedValues = {
    readableFilter: '',
    routerArray: [],
    routerArrayFinal: [],
  },
) => {
  const [currentSlug, setCurrentSlug] = useState(serverSlug);
  const [currentQuery, setCurrentQuery] = useState(serverQuery);
  const router = useRouter();

  //utils methods

  //useEffect's <--------------
  useEffect(() => {
    setCurrentSlug(serverSlug);
    setCurrentQuery(serverQuery);
  }, [serverSlug, serverQuery]);

  //Calculated Params

  //Methods
  const getSlugStringWithExcludedType = (paramType: string) => {
    const paramsWithExcluded = currentSlugParams.filter((slugParams) => {
      return slugParams?.[0]?.name !== paramType;
    });
    return paramsWithExcluded
      .map((slugParam) => cyrillicToTranslit.transform(slugParam[1], '_'))
      .join('/');
  };

  const navigateWithParam = (paramType: string, paramValue: any) => {
    const slugUntouchable = getSlugFilterObjectWithExcludedParams([paramType]);

    const slugUntouchableStirng = filterToSlug(slugUntouchable);

    router.push({
      pathname: `/girls/${slugUntouchableStirng}/${latinize(paramValue)}`,
      query: currentQuery,
    });
  };

  const getNavigateWithParamUrl = (paramType: string, paramValue: any) => {
    const typeIndex = currentSlugParams.findIndex(
      (slugParam) => slugParam?.[0]?.name === paramType,
    );
    const newParams = currentSlugParams.filter((slugParam, index) => {
      return index < typeIndex;
    });
    const paramsWithExcluded = newParams.filter((slugParams) => {
      return slugParams?.[0]?.name !== paramType;
    });
    const slugUrl = paramsWithExcluded
      .map((slugParam) => cyrillicToTranslit.transform(slugParam[1], '_'))
      .join('/');

    const paramValueLatinized = cyrillicToTranslit.transform(paramValue, '_');
    const excludedNewSlugString = `${slugUrl}/${paramValueLatinized}`;
    const updatedSlug = '/girls/' + excludedNewSlugString;
    const newQuery = currentQuery;
    delete newQuery.slug;
    return updatedSlug.replace('//', '/');
  };

  const getSlugFilterObjectWithExcludedParams = (
    typeParams: string[],
    inverted = false,
  ) => {
    const excluded = {};
    Object.keys(filterState.slugFilter).forEach((key) => {
      const foundKeyInExcluded = typeParams.find(
        (typeParam) => typeParam === key,
      );

      if (!inverted) {
        if (!foundKeyInExcluded) {
          excluded[key] = filterState.slugFilter[key];
        }
      } else {
        if (foundKeyInExcluded) {
          excluded[key] = filterState.slugFilter[key];
        }
      }
    });
    return excluded;
  };

  const filterToSlug = (filterObj: any) => {
    return Object.keys(filterObj)
      .map((key) => latinize(filterObj[key]))
      .join('/');
  };

  const latinizeObject = (anyObject: any) => {
    const finalObject = {};
    Object.keys(anyObject).forEach((objectKey) => {
      const value = anyObject[objectKey];
      if (Array.isArray(value)) {
        const latinizedArray = value.map((val) => {
          if (val === null) {
            return null;
          }
          if (Number.isInteger(val)) {
            return val;
          }
          return latinize(val);
        });
        finalObject[objectKey] = latinizedArray;
      } else {
        finalObject[objectKey] = latinize(value);
      }
    });
    return finalObject;
  };

  const applySearchQuery = (query: any, debug = false) => {
    delete query.locationCity;
    delete query.type;
    delete query.format;
    delete query.slug;
    delete query.redirectUrl;
    debug && console.log('==========Applying search query');
    debug && console.log(query);
    const hierarchyFull = [
      'type',
      'format',
      'locationCity',
      'locationUnit',
      'services',
      ...lookFieldsConfig.map((config) => config.field),
    ];
    const baseFilters = ['type', 'format', 'locationCity'];
    const slugUntouchable = getSlugFilterObjectWithExcludedParams(
      baseFilters,
      true,
    );

    const slugUntouchableStirng = filterToSlug(slugUntouchable);

    const cleanedTouchableSlug = getSlugFilterObjectWithExcludedParams([
      ...baseFilters,
      ...Object.keys(query),
    ]);
    debug && console.log('1. Cleaning slug', cleanedTouchableSlug);
    const updatedTouchableSlugString = filterToSlug(cleanedTouchableSlug);

    debug &&
      console.log(
        '2. Updated touchable slug',
        updatedTouchableSlugString,
        ' and untouchable string ',
        slugUntouchableStirng,
      );

    // const queryHasSingleParam = Object.keys(query).length === 1;
    // const queryValueIsAnArray = Array.isArray(query[Object.keys(query)[0]]);
    // const queryValueHasSingleObject =
    //   query[Object.keys(query)[0]]?.length === 1;

    const appendWhiteList = [
      'format',
      'services',
      'locationUnit',
      ...lookFieldsConfig.map((config) => config.field),
    ];

    const appendedSlugStringArray = [];
    const updateQuery = {};

    Object.keys(query)
      .sort((key1, key2) => {
        const key1Index = hierarchyFull.findIndex((key) => key1 === key);
        const key2Index = hierarchyFull.findIndex((key) => key2 === key);
        return key1Index - key2Index;
      })
      .map((queryKey) => {
        const isWhiteListed = Boolean(
          appendWhiteList.find((key) => queryKey === key),
        );
        const queryVal = arrayrize(query[queryKey]);
        if (!isWhiteListed) {
          updateQuery[queryKey] = queryVal;
          return null;
        }
        if (queryVal.length === 1) {
          appendedSlugStringArray.push(latinize(queryVal[0]));
        } else {
          updateQuery[queryKey] = queryVal;
        }
      });

    debug &&
      console.log('Decided to append following - ', appendedSlugStringArray);
    debug && console.log('Decided to query follofing', updateQuery);
    const cyrillicSq = updateQuery['sq'];
    const finalQuery = latinizeObject(updateQuery);
    if (cyrillicSq) {
      finalQuery['sq'] = cyrillicSq;
    }
    const finalSlug =
      `/girls/${slugUntouchableStirng}/${appendedSlugStringArray.join(
        '/',
      )}`.replace('//', '/');
    router.push({
      pathname: finalSlug,
      query: finalQuery,
    });

    // const isWhiteListedKey = Boolean(
    //   appendWhiteList.find((key) => key === Object.keys(query)[0]),
    // );

    // const shouldAppend =
    //   queryHasSingleParam &&
    //   isWhiteListedKey &&
    //   ((queryValueIsAnArray && queryValueHasSingleObject) ||
    //     !queryValueIsAnArray);
    // debug &&
    //   console.log(
    //     `2.5 Deciding if should update HAS SINGLE PARAM - ${queryHasSingleParam}, IS WHITELIST - ${isWhiteListedKey}, IS AN ARRAY WITH SINGLE OBJECT ${
    //       queryValueIsAnArray && queryValueHasSingleObject
    //     } and NOT AN ARRAY ${!queryValueIsAnArray} ==== ${shouldAppend}`,
    //   );
    // if (shouldAppend) {
    //   let appendValue = undefined;
    //   // if (queryValueIsAnArray) {
    //   appendValue = arrayrize(query[Object.keys(query)[0]])[0];
    //   // } else {
    //   // appendValue = Object.keys(query)[0];
    //   // }

    //   const appendedTouchableSlug =
    //     updatedTouchableSlugString + '/' + latinize(appendValue);
    //   debug &&
    //     console.log(
    //       `3. Query should append with value ${appendValue} - `,
    //       appendedTouchableSlug,
    //     );
    //   const finalSlugPath =
    //     '/girls/' + slugUntouchableStirng + '/' + appendedTouchableSlug;
    //   debug && console.log(`4. Pushing to ${finalSlugPath} without params`);
    //   router.push({
    //     pathname: finalSlugPath.replace('//', '/'),
    //     query: {},
    //   });
    // } else {
    //   const updatedQuery = latinizeObject(query);
    //   debug && console.log('3. Query shouldnt append');
    //   const finalSlugPath = '/girls/' + slugUntouchableStirng;
    //   debug &&
    //     console.log(`4. Pushing to ${finalSlugPath} with params`, updatedQuery);
    //   router.push({
    //     pathname: finalSlugPath.replace('//', '/'),
    //     query: updatedQuery,
    //   });
    // }
  };

  const getBasePath = () => {
    const currentCity = filterState.completeFilter['locationCity'];
    const currentType = filterState.completeFilter['type'];
    const currentFormat = filterState.completeFilter['format'];
    let finalPath = '';
    if (currentType) {
      finalPath += `/${currentType}`;
    }

    if (currentCity) {
      finalPath += `/${latinize(currentCity)}`;
    }

    if (currentFormat) {
      finalPath += `/${currentFormat}`;
    }
    return `/girls` + finalPath;
  };

  const resetSearch = () => {
    const currentCity = filterState.completeFilter['locationCity'];
    const currentType = filterState.completeFilter['type'];
    const currentFormat = filterState.completeFilter['format'];
    let finalPath = '';
    if (currentType) {
      finalPath += `/${currentType}`;
    }

    if (currentCity) {
      finalPath += `/${latinize(currentCity)}`;
    }

    if (currentFormat) {
      finalPath += `/${currentFormat}`;
    }
    const pathname = `/girls` + finalPath;
    router.replace({
      pathname: pathname,
      query: {},
    });
    // router.reload();
  };

  //=======

  return {
    currentSlug,
    currentQuery,
    calculatedValues,
    filterState,
    navigateWithParam,
    applySearchQuery,
    resetSearch,
    getNavigateWithParamUrl,
    calculatedValues,
    getBasePath,
    filterValueToString,
  };
};

export default useFilterSlug;
// currentSlugFilter,
// currentSlugParams,
// paramsArray,
// filterString,
// userTranslatedSlug,
// filterRouteArray,

export const arrayrize = (arg1: any) => {
  if (!Boolean(arg1)) {
    return [];
  }
  return Array.isArray(arg1) ? arg1 : [arg1];
};

export const latinize = (arg1: string) => {
  return cyrillicToTranslit.transform(arg1, '_');
};

export const cyrillicaze = (arg1: string) => {
  return cyrillicToTranslit.reverse(arg1, '_');
};

export const idsToArray = (idsArray: string[], idKey: string, items: any) => {
  return items.filter((item: any) => {
    const itemId = String(item[idKey]);
    return Boolean(idsArray.find((id) => itemId));
  });
};
