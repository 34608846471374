import axios from 'axios';
import { axiosInstance } from './axios.interceptors';

export const getLocationCities = async () => {
  const res = await axiosInstance.get('/admin/location');
  return res.data;
};

export const getCompleteLocationConfig = async () => {
  const res = await axiosInstance.get('/admin/location/config');
  return res.data;
};

export const getLocationUnits = async (city: string) => {
  const res = await axiosInstance.get(`/admin/city/${city}`);
  return res.data;
};

export const getLocationUnitsServer = async (city: string) => {
  const res = await axios.get(
    `${process.env.NEXT_PUBLIC_BACKEND_URL}/admin/city/${city}`,
  );
  return res.data;
};
