import { NotificationContext } from '@c/notification/NotificationProvider';
import { Service } from '@c/Settings/SettingsManagePublishings/CreateNewPublishingModal/Step4';
import Typography2 from '@c/Typographies/Typography2';
import { fetchBasicServices } from 'api/girl.service';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from 'react-query';
import { NotificationType } from 'shared/interfaces/notification.interface';
import { theme } from 'theme/theme';
import styles from './styles.module.scss';

type TabLookProps = {
  control: any;
  onUpdate: () => void;
};

export const lookFieldsConfig = [
  {
    field: 'lookType',
    values: [
      {
        name: 'Славянская',
        id: 'slavic',
      },
      {
        name: 'Азиатская',
        id: 'asian',
      },
      {
        name: 'Кавказская',
        id: 'caucas',
      },
      {
        name: 'Aфриканская',
        id: 'afro',
      },
      {
        name: 'Другая',
        id: 'other',
      },
    ],
    title: 'Тип Внешности',
  },
  {
    field: 'bodyType',
    values: [
      {
        name: 'Спортивная',
        id: 'sporty',
      },
      {
        name: 'Нормальная',
        id: 'normal',
      },
      {
        name: 'Полная',
        id: 'fat',
      },
    ],
    title: 'Фигура',
  },
  {
    field: 'eyeColor',
    values: [
      {
        name: 'Голубые',
        id: 'blue',
      },
      {
        name: 'Зеленые',
        id: 'green',
      },
      {
        name: 'Карие',
        id: 'brown',
      },
      {
        name: 'Серые',
        id: 'grey',
      },
      {
        name: 'Другие',
        id: 'other',
      },
    ],
    title: 'Цвет Глаз',
  },
  {
    field: 'hair',
    values: [
      {
        name: 'Блондинка',
        id: 'blond',
      },
      {
        name: 'Брюнетка',
        id: 'brunette',
      },
      {
        name: 'Рыжая',
        id: 'ginger',
      },
      {
        name: 'Черные',
        id: 'black',
      },
      {
        name: 'Цветные',
        id: 'colored',
      },
      {
        name: 'Другой',
        id: 'other',
      },
    ],
    title: 'Цвет Волос',
  },
  {
    field: 'breastType',
    values: [
      {
        name: 'Натуральная',
        id: 'natural',
      },
      {
        name: 'Силикон',
        id: 'silicon',
      },
    ],
    title: 'Тип Груди',
  },
  {
    field: 'hairCut',
    values: [
      {
        name: 'Депилляция',
        id: 'bold',
      },
      {
        name: 'Стрижка',
        id: 'haircut',
      },
      {
        name: 'Натуральная',
        id: 'natural',
      },
    ],
    title: 'Интимная Стрижка',
  },
];

const TabLook: React.FC<TabLookProps> = ({ control, onUpdate }) => {
  return (
    <div>
      {lookFieldsConfig.map((field, index) => {
        return (
          <Controller
            key={index}
            name={field.field}
            control={control}
            render={(props) => (
              <div className={styles.container}>
                <Typography2 weight="500" size="l">
                  {field.title}
                </Typography2>
                <div className={styles.content}>
                  {field.values?.map((field, index) => {
                    let selected = false;

                    if (Array.isArray(props.field.value))
                      selected = Boolean(
                        props.field.value?.find(
                          (fieldId: string) => fieldId === field.id,
                        ),
                      );
                    else selected = props?.field?.value === field?.id;

                    const handleSelect = (short_id: string) => {
                      if (Array.isArray(props.field.value)) {
                        props.field.onChange([...props.field.value, short_id]);
                      } else {
                        props.field.onChange([short_id]);
                      }
                      onUpdate();
                    };

                    const handleUnselect = (short_id: string) => {
                      if (Array.isArray(props.field.value)) {
                        props.field.onChange(
                          props.field.value.filter(
                            (s_id: string) => short_id !== s_id,
                          ),
                        );
                      } else {
                        props.field.onChange([]);
                      }
                      onUpdate();
                    };

                    return (
                      <div
                        key={index}
                        onClick={() =>
                          selected
                            ? handleUnselect(field.id)
                            : handleSelect(field.id)
                        }
                        className={
                          selected ? styles.selected : styles.unselected
                        }
                      >
                        <Typography2
                          weight="400"
                          size="m"
                          color={
                            selected
                              ? theme.palette.primary.main
                              : theme.palette.textTransluent.main
                          }
                        >
                          {field.name}
                        </Typography2>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          />
        );
      })}
      {/* <Controller
        name="services"
        control={control}
        render={(props) => (
          <div className={styles.container}>
            {data?.map((service: Service) => {
              const selected = Boolean(
                props.field.value?.find(
                  (valService: string) => valService === service.short_id,
                ),
              );

              const handleSelect = (short_id: string) => {
                if (Array.isArray(props.field.value)) {
                  props.field.onChange([...props.field.value, short_id]);
                } else {
                  props.field.onChange([short_id]);
                }
                onUpdate();
              };

              const handleUnselect = (short_id: string) => {
                props.field.onChange(
                  props.field.value.filter((s_id: string) => short_id !== s_id),
                );
                onUpdate();
              };

              return (
                <div
                  onClick={() =>
                    selected
                      ? handleUnselect(service.short_id)
                      : handleSelect(service.short_id)
                  }
                  className={selected ? styles.selected : styles.unselected}
                >
                  <Typography2
                    weight="400"
                    size="m"
                    color={
                      selected
                        ? theme.palette.primary.main
                        : theme.palette.textTransluent.main
                    }
                  >
                    {service.name}
                  </Typography2>
                </div>
              );
            })}
          </div>
        )}
      /> */}
    </div>
  );
};

export default TabLook;
